import { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import '../assets/css/style.css';
import '../assets/js/main'
import PureCounter from '@srexi/purecounterjs'
import CountUp from 'react-countup';

new PureCounter()


function Stats() {
  return (
  <section id="facts">
    <div className="site-section bg-dark count-numbers">
      <div className="container">
        <div className="row">
         <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-4 mb-lg-0">
          <hr/>
          <div className="counter-wrap text-center">
            <span><h3 className="text-info">We are approved in</h3></span>
            <strong className="counter d-block"><span className="number" data-number="8"><CountUp start={0} end={8} duration={5} /></span></strong>
            
            <span>Counties<h4 className="display-6 text-white">Clayton, Douglas, Henry, Cherokee, Forsyth, Gwinnett, Dekalb, Fulton</h4></span>
            
          </div>
        </div>
        {/* <div className="col-6 col-sm-6 col-md-6 col-lg-3 mb-4 mb-lg-0">
          <div className="counter-wrap text-center">
            <strong className="counter d-block"><span className="number" data-number="530"><CountUp start={0} end={8} duration={5} /></span></strong>
            <span>Nurse Staff</span>
          </div>
        </div> */}
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-4 mb-lg-0">
          <hr/>
          <div className="counter-wrap text-center">
          <span><h3 className="text-info">Our clients are</h3></span>
            <strong className="counter d-block"><span className="number" data-number="100"><CountUp start={0} end={100} duration={3} />%</span></strong>
            <span>Satisfied</span>
          </div>
        </div>
        {/* <div className="col-6 col-sm-6 col-md-6 col-lg-3 mb-4 mb-lg-0">
          <div className="counter-wrap text-center">
            <strong className="counter d-block"><span className="number" data-number="7020"><CountUp start={0} end={8} duration={5} /></span></strong>
            <span>Happy People</span>
          </div>
        </div> */}
        <hr/>
      </div>
    </div>
    </div>
  </section>)

}

export { Stats };