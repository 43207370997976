
import './App.css';
import Home from './pages/home';
import AboutPage from './pages/about';
import Completion from './components/completion';

import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';
// import {loadStripe} from '@stripe/stripe-js';
// import { useState, useRef, useEffect } from "react";



function App() {
// const [ stripePromise, setStripePromise ] = useState(null);
const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route index element={<Home />} />
      <Route path="about" element={<AboutPage />} />
      {/* <Route path="checkout" element={<Payment stripePromise={stripePromise} />} /> */}
      <Route path="completion" element={<Completion />} />
      {/* <Route path="commission" element={<CommissionPage />} />
      <Route path="commissionmembers" element={<CMemberPage />} />
      <Route path="clerk/:designation" element={<ClerkPage />} />
      <Route path="dashboard" element={<MemberArea />} />
      <Route path="news" element={<News />} />
      <Route path="news-detail/:slug" element={<Article />} /> */}
    </>
  )
);
  // useEffect(() => {
  //   // fetch("/api/config").then(async (r) => {
  //   //   const { publishableKey } = await r.json();
  //     setStripePromise(loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY));
  //   // });
  // }, []);
  return (
    <>
  
      <RouterProvider router={router} />
      <SpeedInsights />
      <Analytics />

    </>
    
  );
}

export default App;
