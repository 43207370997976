import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";

function ContactUs() {
  const form = useRef();
  const [emailSent, setEmailSent] = useState(false);
  const [open, setOpen] = useState(true);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_a5jg8ug", "template_tq6l5rz", form.current, {
        publicKey: "Z5dr8rrU-gXUt4asM",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          setEmailSent(true);
          console.log(emailSent);
          resetForm();
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  const resetForm = () => {
    form.current.reset();
    // setEmailSent(false);
  };

  return (
    <section id="contact" className=" section-bg bg-secondary">
      <div className="container" data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
        <h3 className="text-white text-center">Contact Us</h3>
        <div className="lead text-white">Have any queries or questions, don't hesitate to reach out to us. Please fill out the form below and we will get back to you as soon as possible.</div>
        <div className="screen-reader-response">
          <p role="status" aria-live="polite" aria-atomic="true"></p>
          <ul></ul>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <div
            className="row make-an-appointment-form"
            style={{ lineHeight: "30px" }}
          >
            <div className="col-sm-6 form-group">
              <p>
                <span className="form-control-wrap" data-name="name">
                  <input
                    size="30"
                    className="form-control"
                    aria-required="true"
                    aria-invalid="false"
                    placeholder="Your name"
                    type="text"
                    name="from_name"
                    required
                  />
                </span>
              </p>
              <p>
                <span className="form-control-wrap" data-name="email">
                  <input
                    size="30"
                    className="form-control"
                    aria-required="true"
                    aria-invalid="false"
                    placeholder="Your email"
                    type="email"
                    name="email"
                    required
                  />
                </span>
              </p>
              <p>
                <span className="form-control-wrap" data-name="subject">
                  <input
                    size="30"
                    className="form-control"
                    aria-invalid="false"
                    placeholder="Subject"
                    type="text"
                    name="subject"
                    required
                  />
                </span>
              </p>
            </div>
            <div className="col-sm-6">
              <p>
                <span className="form-control-wrap" data-name="message">
                  <textarea
                    cols={30}
                    rows={8}
                    className="form-control"
                    aria-invalid="false"
                    placeholder="Your message"
                    type="textarea"
                    name="message"
                    required
                    style={{
                      height: "160px",
                    }}
                  />
                </span>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6">
              {emailSent && (
                <Collapse in={open}>
                  <Alert
                    severity="success"
                    onClose={() => {
                      setOpen(false);
                    }}
                  >
                    Message sent successfully. Thanks for contacting us!
                  </Alert>
                </Collapse>
              )}
              <p
                style={{
                  marginTop: "20px",
                  marginLeft: "11px",
                  fontSize: "15px",
                  lineHeight: "18px",
                }}
                className="text-white"
              >
                All fields are required
              </p>
            </div>
            <div className="col-xs-6">
              <p className="form-button pull-end" style={{ width: "100%" }}>
                <button className="btn btn-primary" type="submit" value="Send">
                  Send
                </button>
              </p>
            </div>
          </div>
          <div
            id="response"
            className="wpcf7-response-output"
            aria-hidden="true"
          ></div>
        </form>
      </div>
    </section>
  );
}

function ContactForm() {
  const [inputs, setInputs] = useState({});
  const [textarea, setTextarea] = useState(
    "The content of a textarea goes in the value attribute"
  );

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setTextarea(event.target.value);

    var contactParams = {
      from_name: inputs.name,
      from_email: inputs.email,
      message: inputs.message,
    };

    emailjs
      .send("service_a5jg8ug", "template_tq6l5rz", contactParams, {
        publicKey: "Z5dr8rrU-gXUt4asM",
        //   privateKey: '<YOUR_PRIVATE_KEY>', // optional, highly recommended for security reasons
      })
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );
  };

  const templateParams = {
    name: inputs.name,
    email: inputs.email,
    message: inputs.message,
  };

  emailjs
    .send("service_a5jg8ug", "template_tq6l5rz", templateParams, {
      publicKey: "Z5dr8rrU-gXUt4asM",
      //   privateKey: '<YOUR_PRIVATE_KEY>', // optional, highly recommended for security reasons
    })
    .then(
      (response) => {
        console.log("SUCCESS!", response.status, response.text);
      },
      (err) => {
        console.log("FAILED...", err);
      }
    );
  return (
    <section id="contact" className=" section-bg bg-secondary">
      <div className="container">
        <h3 className="text-white text-center">Book an Appointment</h3>

        <div className="screen-reader-response">
          <p role="status" aria-live="polite" aria-atomic="true"></p>
          <ul></ul>
        </div>
        <form
          action=""
          method="post"
          className=""
          aria-label="Contact form"
          novalidate="novalidate"
          data-status="init"
          onSubmit={handleSubmit}
        >
          <div
            className="row make-an-appointment-form"
            style={{ lineHeight: "30px" }}
          >
            <div className="col-sm-6 form-group">
              <p>
                <span className="form-control-wrap" data-name="name">
                  <input
                    size="30"
                    className="form-control"
                    aria-required="true"
                    aria-invalid="false"
                    placeholder="Your name"
                    type="text"
                    name="names"
                    value={inputs.names || ""}
                    onChange={handleChange}
                  />
                </span>
              </p>
              <p>
                <span className="form-control-wrap" data-name="email">
                  <input
                    size="30"
                    className="form-control"
                    aria-required="true"
                    aria-invalid="false"
                    placeholder="Your email"
                    type="email"
                    name="email"
                    value={inputs.email || ""}
                    onChange={handleChange}
                  />
                </span>
              </p>
              <p>
                <span className="form-control-wrap" data-name="subject">
                  <input
                    size="30"
                    className="form-control"
                    aria-invalid="false"
                    placeholder="Your subject"
                    type="text"
                    name="subject"
                    value={inputs.subject || ""}
                    onChange={handleChange}
                  />
                </span>
              </p>
            </div>
            <div className="col-sm-6">
              <p>
                <span className="form-control-wrap" data-name="message">
                  <textarea
                    cols={30}
                    rows={8}
                    className="form-control"
                    aria-invalid="false"
                    placeholder="Your message"
                    type="textarea"
                    name="message"
                    defaultValue="Your Message"
                    onChange={handleChange}
                    style={{
                      height: "160px",
                    }}
                  />
                </span>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6">
              <p
                style={{
                  marginTop: "20px",
                  marginLeft: "11px",
                  fontSize: "15px",
                  lineHeight: "18px",
                }}
                className="text-white"
              >
                All fields are compulsory
              </p>
            </div>
            <div className="col-xs-6">
              <p className="form-button pull-right" style={{ width: "100%" }}>
                <button
                  className="btn btn-primary"
                  type="submit"
                  value="Make an Appointment"
                >
                  Submit
                </button>
              </p>
            </div>
          </div>
          <div className="wpcf7-response-output" aria-hidden="true"></div>
        </form>
      </div>
    </section>
  );
}

export { ContactForm, ContactUs };
