import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "../assets/css/style.css";
import "../assets/js/main";
import "../assets/scss/style.scss";

import { Header } from "../components/header";
import { Footer } from "../components/footer";
import { Teams } from "../components/teams";

function AboutPage() {
  return (
    <>
      {/* <div id="overlayer"></div>
        <div className="loader">
            <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
            </div>
        </div> */}

      <div className="site-wrap">
        <div className="site-mobile-menu site-navbar-target">
          <div className="site-mobile-menu-header">
            <div className="site-mobile-menu-close mt-3">
              <span className="icon-close2 js-menu-toggle"></span>
            </div>
          </div>
          <div className="site-mobile-menu-body"></div>
        </div>
        <Header />
        <main id="main">
          <section id="hero">
            <div
              class="slide-item overlay"
              style={{
                backgroundImage:
                  "url(" + require("../assets/images/goal.jpg") + ")",
              }}
            >
              <div class="container">
                <div class="row justify-content-center text-center">
                  <div class="col-lg-6 align-self-center">
                    <h1 class="heading mb-3">About Us</h1>
                    {/* <p class="lead text-white mb-5">
                      Just as getting older can allow for a more relaxed
                      lifestyle, it can also present new obstacles and
                      challenges.
                    </p> */}
                    <p class="lead text-white mb-5">
                      We are dedicated to providing the satisfaction and quality
                      of life of your loved ones.
                    </p>
                    <p>
                      <a
                        href="https://wa.me/+14049146754?text=I%20want%20to%20enquire%20about%20your%20services"
                        class="btn btn-secondary"
                      >
                        Get In Touch
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class="site-section">
            <div class="container">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-4 mb-4">
                  <div class="blog-entry">
                    <a href="#" class="d-block" id="mission">
                      <img
                        src={require("../assets/images/caregiver-male.jpg")}
                        alt="caregiver"
                        class="img-fluid"
                      />
                    </a>
                    <div class="post-meta d-flex justify-content-center">
                      <span>
                        <span>Our Mission</span>
                      </span>
                    </div>
                    {/* <h2>We're Providing the Quality Care</h2> */}
                    <span className="lead">
                      Our mission is to improve our clients lives and health by
                      providing professional services, enabling them to achieve
                      their full potential of every day.
                    </span>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 mb-4" id="caregivers">
                  <div class="blog-entry">
                    <a href="#" class="d-block">
                      <img
                        src={require("../assets/images/outdoors.jpg")}
                        alt="caregiver"
                        class="img-fluid"
                      />
                    </a>
                    <div class="post-meta d-flex justify-content-center">
                      <span>
                        <span>Our Caregivers</span>
                      </span>
                    </div>
                    {/* <h2><a href="#">We're Providing the Quality Care</a></h2> */}
                    <span className="lead">
                      We ensure our caregivers are highly skilled and
                      compassionate while giving attention to our clients. With
                      them, you can be sure your loved ones are always safe and
                      have the highest level of care. Since we maintain such a
                      high level of care, we offer regular training for our
                      employees so they can stay in touch with the latest trends
                      in this field of work.
                    </span>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 mb-4" id="goal">
                  <div class="blog-entry">
                    <a href="#" class="d-block">
                      <img
                        src={require("../assets/images/outdoors-1.jpg")}
                        alt="caregiver"
                        class="img-fluid"
                      />
                    </a>
                    <div class="post-meta d-flex justify-content-center">
                      <span>
                        <span>Our Goal</span>
                      </span>
                    </div>
                    {/* <h2><a href="#">We're Providing the Quality Care</a></h2> */}
                    <span className="lead">
                      The main goal of My Passion for Seniors is to bring smiles
                      to the faces of our patients and their families. Our home
                      care services target a wide range of elderly people. We
                      seek to make a significant difference in your life by
                      being there for your family during the times you need us
                      most.
                    </span>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row justify-content-center mb-5">
                <div class="col-lg-7 text-center mx-auto">
                  <h2 class="heading mb-3 text-black">Our Services</h2>
                  <p className="lead">
                    Our caregivers act as companion and friend when family
                    members or loved ones can not be present, allowing family
                    and firends to have peace of mind knowing that their loved
                    one is getting the attention they deserve, the companionship
                    they desire and the care they need to remain safe in their
                    home.
                  </p>
                </div>
              </div>
              <div className="row">
                {/* <div className="col-lg-6">
                  <img
                    src={require("../assets/images/happy.jpg")}
                    alt="Image"
                    className="img-fluid shadow"
                  />
                </div> */}
                <div className="col-lg-12" id="companionship">
                  <ul className="secondary lead">
                    <img
                      src={require("../assets/images/happy.jpg")}
                      alt="caregiver"
                      className="img-fluid shadow float-start p-2"
                      width={"500px"}
                    />
                    <span className="display-6">Companionship Care</span>
                    <p className="">
                      Our caregivers provide more than just assistance with
                      daily tasks; they also offer companionship and emotional
                      support. Whether it's engaging in meaningful
                      conversations, playing games, or simply enjoying each
                      other's company, we strive to foster genuine connections
                      and combat loneliness.
                    </p>
                    We offer this special service which include:
                    <li>Pick up mail</li>
                    <li>Sorting and organizing</li>
                    <li>Transportation to doctor’s appointments and outing</li>
                    <li>Meal preparation and serving</li>
                    <li>Light housekeeping</li>
                    <li>Laundry and ironing services</li>
                    <li>Shopping and errand services</li>
                    <li>Medication reminders</li>
                    <li>Correspondence services</li>
                    <li>Walking companion</li>
                    <li>Packing and unpacking.</li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <hr />
                <div className="col-lg-12 mt-10" id="homemaking">
                  <ul className="secondary lead">
                    <img
                      src={require("../assets/images/homecare.jpg")}
                      alt="caregiver"
                      className="img-fluid shadow float-end p-2"
                      width={"500px"}
                    />
                    <span className="display-6">Homemaking Care</span>
                    <p>
                      We offer household services in order to keep your home a
                      clean and safe place. Our services are available 24 hours
                      a day, seven days a week. Homemaking services are a great
                      option for when you first begin using assistance in the
                      comfort of your home.{" "}
                    </p>
                    <p>
                      A clean and tidy living environment contributes to both
                      physical and mental well-being. Our caregivers assist with
                      light housekeeping tasks such as laundry, dishes, and
                      general tidying up, creating a comfortable and safe home
                      environment for our clients.
                    </p>
                    It includes:
                    <li>Meal planning and preparation</li>
                    <li>Light housekeeping</li>
                    <li>Vacuuming and dusting</li>
                    <li>Changing bedsheets</li>
                    <li>Laundry</li>
                    <li>Dishwashing</li>
                    <li>Assistance in sorting and reading the mail etc.</li>
                  </ul>
                </div>
                {/* <div className="col-lg-6">
                  <img
                    src={require("../assets/images/homecare.jpg")}
                    alt="Image"
                    className="img-fluid shadow"
                  />
                </div> */}
              </div>
              <div className="row">
                <hr />
                {/* <div className="col-lg-6">
                  <img
                    src={require("../assets/images/homecare-wide.jpg")}
                    alt="Image"
                    className="img-fluid shadow"
                  />
                </div> */}
                <div className="col-lg-12" id="personal">
                  <ul className="secondary lead">
                    <img
                      src={require("../assets/images/homecare-wide.jpg")}
                      alt="caregiver"
                      className="img-fluid shadow float-start p-2"
                      width={"500px"}
                    />
                    <span className="display-6">Personal Care</span>
                    <p>
                      Personal Care is one of the main services in the Home Care
                      industry. It includes assistance with everyday activities
                      to help the ones in need and make the elderly life
                      peaceful. Personal care services are related to assistance
                      with dressing, hygiene, and incontinence services.{" "}
                    </p>
                    <p>
                      We understand that every senior has unique needs and
                      preferences. That's why we offer personalized care plans
                      tailored to each individual, ensuring that they receive
                      the support and assistance they need to thrive while
                      maintaining their independence.
                    </p>
                    It includes the following activities:
                    <li>Bathing assistance</li>
                    <li>
                      Incontinence Care (toileting and grooming, and dressing)
                    </li>
                    <li>Blood pressure and weight checks</li>
                    <li>ADL’s (Activity of Daily Living)</li>
                    <li>Feeding.</li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <hr />
                <div className="col-lg-12 mt-10" id="privateduty">
                  <ul className="secondary lead">
                    <img
                      src={require("../assets/images/homecare-2-wide.jpg")}
                      alt="caregiver"
                      className="img-fluid shadow float-end p-2"
                      width={"500px"}
                    />
                    <span className="display-6">24/7 Private Duty</span>
                    <p>
                      Professional and trained home caregivers take care of your
                      loved ones in a way that ensures meeting the requirements
                      of their individual needs round the clock.{" "}
                    </p>
                    <p>
                      From bathing and dressing to medication reminders and
                      mobility assistance, our caregivers are trained to provide
                      comprehensive support with activities of daily living,
                      enabling seniors to live comfortably and safely in their
                      own homes.
                    </p>
                    Our caregiver hours are :<li>7am – 7pm, 7pm – 7am,</li>
                    <li>8am – 8pm and 8pm – 8am or </li>
                    <li>7am – 3pm, 3pm – 11pm, 11pm – 7am</li>
                  </ul>
                </div>
                {/* <div className="col-lg-6">
                  <img
                    src={require("../assets/images/homecare-2-wide.jpg")}
                    alt="Image"
                    className="img-fluid shadow"
                  />
                </div> */}
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
}

export default AboutPage;
