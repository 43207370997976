import {useEffect, useState} from 'react';

function Completion() {
  // const [ messageBody, setMessageBody ] = useState('');
  // const { stripePromise } = props;

  // useEffect(() => {
  //   if (!stripePromise) return;

  //   stripePromise.then(async (stripe) => {
  //     const url = new URL(window.location);
  //     const clientSecret = url.searchParams.get('payment_intent_client_secret');
  //     const { error, paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

  //     setMessageBody(error ? `> ${error.message}` : (
  //       <>&gt; Payment {paymentIntent.status}: <a href={`https://dashboard.stripe.com/test/payments/${paymentIntent.id}`} target="_blank" rel="noreferrer">{paymentIntent.id}</a></>
  //     ));
  //   });
  // }, [stripePromise]);

  return (
    <>
      <div className='container'>
      <h1 className='display-1'>Thank you for your patronage!</h1>
      <a href="/" className='linklink-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover'>Return to Home</a>
      </div>
      {/* <div id="messages" role="alert" style={messageBody ? {display: 'block'} : {}}>{messageBody}</div> */}
    </>
  );
}

export default Completion;