import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.js";

import "../assets/css/style.css";
import "../assets/js/main";

function Testimonials() {
  document.querySelectorAll("body")[0].style.backgroundColor = "whiteSmoke";
  // useEffect(() => {
  //   // listen for Swiper events using addEventListener
  //   swiperElRef.current.addEventListener("swiperprogress", (e) => {
  //     const [swiper, progress] = e.detail;
  //     console.log(progress);
  //   });

  //   swiperElRef.current.addEventListener("swiperslidechange", (e) => {
  //     console.log("slide changed");
  //   });
  // }, []);
  return (
    <section id="testimonials" className="section-bg">
      <div className="site-section bg-light">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-5 mb-lg-0">
              <div className="testimonial text-center">
                <img
                  src={require("../assets/images/mypassion4seniors_sq_logo.png")}
                  alt="testifier"
                  className="img-fluid"
                />
                <blockquote>
                  <p className="quote">
                    The caregiver from My Passion for Seniors, LLC was a
                    dedicated caregiver for my mother for several years. Her
                    compassionate approach and professionalism made her a
                    trusted companion for my mother as she navigated the
                    challenges of aging. Her attention to detail, punctuality,
                    and proactive nature ensured that my mother's needs were
                    always met beyond expectations. Such qualities make My
                    Passion for Seniors an exemplary choice in the caregiving
                    field. I wholeheartedly recommend My Passion for Seniors LLC
                    for anyone seeking quality and compassionate care for their
                    elderly loved ones.
                  </p>
                  <cite className="author">Christine C. Hodde</cite>
                </blockquote>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-5 mb-lg-0">
              <div className="testimonial text-center">
                <img
                  src={require("../assets/images/mypassion4seniors_sq_logo.png")}
                  alt="testifier"
                  className="img-fluid"
                />
                <blockquote>
                  <p className="quote">
                    My Passion for Seniors, LLC was an invaluable support for my
                    mother. Despite her challenging behavior due to dementia,
                    their caregivers provided exceptional care with dignity and
                    love. Unlike other caregivers, they managed every aspect of
                    my mother's needs, ensuring her safety and well-being. The
                    caregiver even went beyond her duties, arranging special
                    visits during COVID restrictions and attending doctor
                    appointments. With their help, my family could rest assured
                    of my mother's excellent care. The caregiver's dedication
                    extended to visiting my mother during her final days and
                    attending her funeral. Their compassion and professionalism
                    make My Passion for Seniors the ideal choice for caregiving
                    services.
                  </p>
                  <cite className="author">Mark Maloney</cite>
                </blockquote>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-5 mb-lg-0">
              <div className="testimonial text-center">
                <img
                  src={require("../assets/images/mypassion4seniors_sq_logo.png")}
                  alt="Image"
                  className="img-fluid"
                />
                <blockquote>
                  <p className="quote">
                    When we were going through our toughest moments, it seemed
                    like My Passion for Seniors showed up on our
                    doorstep, kind of like an angel. Within a short time she had
                    our caregivers scheduled, shared some simple strategies that
                    helped me tremendously and calmed me and my husband down.
                    She just knew what to do. My husband had serious back
                    surgery and was totally bedridden. I wasn't sure what to do
                    but our caregivers sure did! They are empathetic, loving and
                    were able to quickly get our routine started. Not only are
                    they taking care of my husband, they are taking care of me.
                    I am so grateful for My Passion for Seniors and their caregivers. 
                  </p>
                  <p>
                  Sincerely, <br/>A
                    thankful and appreciative client
                  </p>
                  <cite className="author">Cindy Olson</cite>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
